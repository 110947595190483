import React, { useEffect } from "react";
//
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
// store utils
import { useStore } from "outstated";
import { AppStore } from "pages/_app";
// mui
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import Authenticator from "components/Authenticator";

export default function Login() {
  const router = useRouter();
  const theme = useTheme();

  const { user } = useStore(AppStore);

  // TODO
  // clean up
  // if they signed up
  // show menu
  // useEffect(() => {
  //   if (user) {
  //     // if there was a login on this page,
  //     // redirect them to /listings
  //     // router.push("/listings");
  //   }
  // }, [user]);

  return (
    <>
      <NextSeo
        title={"Login - OfferMarket"}
        description={"Login to OfferMarket.us"}
        image={`${process.env.absoluteUrl}/omlogo_placeholder.png`}
        canonical="https://www.offermarket.us/login"
        openGraph={{
          url: `${process.env.absoluteUrl}/login`,
          title: "Login - OfferMarket",
          description: "Login to OfferMarket.us",
          images: [
            {
              url: `${process.env.absoluteUrl}/omlogo_placeholder.png`,
              alt: "Login to OfferMarket.us",
            },
          ],
          site_name: "OfferMarket.us",
        }}
        twitter={{
          handle: "@OfferMarketUS",
          site: "@OfferMarketUS",
          cardType: "summary_large_image",
        }}
      />
      <Box
        sx={{
          margin: theme.spacing(20, "auto"),
          width: "25%",
          height: "50vh",
          [theme.breakpoints.down("md")]: {
            margin: theme.spacing(2, 1),
            width: "96%",
            height: "80vh",
          },
        }}
      >
        <Authenticator />
      </Box>
    </>
  );
}
